import React, { useContext, useEffect, useState } from 'react';
import { ShopContext } from '../../contexts/ShopContext';
import ProductService from '../../services/ProductService';
import TitleBar from '../utils/TitleBar/TitleBar';
import styles from './styles.module.css';
import { Button, Container, Grid } from '@material-ui/core';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { ReactComponent as ReduceShoppingCart } from '../utils/ProductView/ReduceShoppingCart.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CategoryListView from '../utils/CategoryListView';
import { CartContext } from '../../contexts/CartContext';
import AddToCartDialog from '../popups/AddToCartDialog';

export default function ProductDetailsPage(props) {
  const { shop, theme } = useContext(ShopContext);
  const { cart, itemsCount, addItemToCart, reduceItemFromCart } = useContext(CartContext);
  const [category, setCategory] = useState(null);
  const [product, setProduct] = useState(null);
  const [cartQty, setCartQty] = useState(0);
  const [showEditQuantityDialog, setShowEditQuantityDialog] = useState(false);

  const { shopId, categoryId, productId } = props?.match?.params;

  useEffect(() => {
    if (!shop || !categoryId) {
      return;
    }

    async function loadCategory() {
      const category = await ProductService.getCategory(shop, categoryId);
      setCategory(category);
    }

    loadCategory();
  }, [shop, categoryId]);

  useEffect(() => {
    if (!shop || !productId) {
      return;
    }

    async function loadProduct() {
      const product = await ProductService.getProduct(shop, productId);
      setProduct(product);
    }

    loadProduct();
  }, [shop, productId]);

  useEffect(() => {
    if (!itemsCount && !cart) return;
    const cartProduct = cart.products[productId];
    setCartQty(cartProduct?.quantity || 0);
  }, [itemsCount, cart, productId]);

  if (shop.shop_id !== shopId) {
    return (
      <div>
        <h3>Invalid shop data! Please refresh the page</h3>
        <div>
          Contect shop ID: {shop.shop_id}, URL shop ID: {shopId}
        </div>
      </div>
    );
  }

  const addToCartBtnPressed = () => {
    addItemToCart(product);
  };
  const removeToCartBtnPressed = () => {
    reduceItemFromCart(product);
  };

  return (
    <div>
      <TitleBar shop={shop} />

      <div
        onClick={() => {
          props.history.push(`/${shop.shop_id}`);
        }}
      >
        <CategoryListView shop={shop} fcnSetSelectedCategory={setCategory} />
      </div>
      {!product || !category ? (
        <div>Loading...</div>
      ) : (
        <Container maxWidth="lg">
          <Grid container className={styles.productDetailWrapper}>
            <Grid item xs={12} md={4} lg={5} className={styles.productImgDiv}>
              <img src={product?.image} alt={product.name} className={styles.productImg} />
              {product.stock <= 0 && (
                <div className={styles.soldOut}>
                  <span className={styles.soldOutText}>SOLD OUT</span>{' '}
                </div>
              )}
              {(product.stock - product.stock_on_hold) <= 0 && (
                <div className={styles.soldOut}>
                  <span className={styles.soldOutText}>ON HOLD</span>
                </div>
              )}
            </Grid>
            <Grid item sm={12} md={1} style={{ minHeight: 40 }}></Grid>
            <Grid item xs={12} md={7} lg={6} className={styles.productContentDiv}>
              <h2 className={styles.productName}>{product.name}</h2>
              <span>{product.description}</span>
              <div className={styles.priceWrapper}>
                <span className={styles.productMrp}>MRP: {product.price}</span>
                <span className={styles.productPrice}>Price: {product.price}</span>
              </div>
              <div className={styles.cartButton}>
                {cartQty <= 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: theme?.primary,
                      color: 'white',
                    }}
                    size="small"
                    disabled={product.stock <= 0}
                    className={styles.addToCartBtnText}
                    onClick={addToCartBtnPressed}
                  >
                    Add to Cart
                  </Button>
                ) : (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: theme?.primary,
                        color: 'white',
                      }}
                      size="small"
                    >
                      <ReduceShoppingCart onClick={removeToCartBtnPressed} />
                    </Button>
                    <div className={styles.cartQuantityText}>
                      <span>{cartQty}</span>
                    </div>
                    <div className={styles.cartQuantityEditLayout}>
                      <EditOutlinedIcon
                        onClick={() => {
                          setShowEditQuantityDialog(true);
                        }}
                      />
                    </div>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: theme?.primary,
                        color: 'white',
                      }}
                      size="small"
                    >
                      <AddShoppingCartIcon onClick={addToCartBtnPressed} />
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      )}

      <AddToCartDialog
        product={product}
        showPopup={showEditQuantityDialog}
        dismissPopup={() => {
          setShowEditQuantityDialog(false);
        }}
      />
    </div>
  );
}
