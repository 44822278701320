import React, { useEffect, useState, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, TextField } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import styles from './styles.module.css';
import LoadingDialog from '../utils/LoadingDialog';
import { DEFAULT_CURRENCY, ORDER_TYPE, STORAGE_KEY } from '../../const';
import { useLocation } from 'react-router-dom';
import { handlePayment } from '../utils/HandlePayment';

export default function PlaceOrderDialog(props) {
  const { shop, cart, fnOnBack } = props;
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const orderType = queryParams.get('order_type') || 'default';

  const [customer, setCustomer] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [orderFields, setOrderFields] = useState(null);
  const [orderFieldsTobeCollected, setOrderFieldsTobeCollected] = useState([]);
  const [error, setError] = useState(null);

  const formValueChanged = (event) => {
    const { name, value } = event.target;
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  const _placeOrder = async (_customer) => {
    await handlePayment({
      shop,
      cart,
      customerDetails: _customer || customer,
      setShowLoading,
      setError,
    });
  };

  const placeOrderBtnPressed = async () => {
    setError(null);
    const _errors = [];

    const requiredFields = orderFields.filter((orderField) => orderField.required);
    for (const requiredField of requiredFields) {
      if (!customer[requiredField.id]) {
        _errors.push(`${requiredField.label} should not be empty`);
      }
    }
    if (_errors.length) {
      setError(_errors[0]);
      return;
    }

    await _placeOrder(customer);
  };

  useEffect(() => {
    if (!orderFields) return;

    const _customer = {};
    const _orderFieldsTobeCollected = [];
    const _editableFields = [];

    orderFields.forEach((orderField) => {
      const existingValue = customer[orderField.id];
      _customer[orderField.id] = existingValue || '';
      if ((!existingValue && orderField.required) || (existingValue && orderField.editable)) {
        _orderFieldsTobeCollected.push(orderField);
      }
      if (orderField.editable) {
        _editableFields.push(orderField);
      }
    });

    if (_orderFieldsTobeCollected.length === 0) {
      return _placeOrder(_customer);
    } else {
      _editableFields.forEach((editableField) => {
        const alreadyExists = _orderFieldsTobeCollected.some((field) => field.id === editableField.id);
        if (!alreadyExists) {
          _orderFieldsTobeCollected.push(editableField);
        }
      });
    }

    setCustomer(_customer);
    setOrderFieldsTobeCollected(_orderFieldsTobeCollected);
    //eslint-disable-next-line
  }, [orderFields]);

  useEffect(() => {
    const customerDetails = JSON.parse(localStorage.getItem(STORAGE_KEY.CUSTOMER_DETAILS) || '{}');
    let customerObj = { ...customerDetails };

    queryParams.forEach((value, key) => {
      customerObj[key] = value;
    });
    setCustomer(customerObj);

    const orderFields = ORDER_TYPE[orderType] || [];
    setOrderFields(orderFields);
  }, [orderType, queryParams]);

  useEffect(() => {
    setShowLoading(!orderFieldsTobeCollected.length);
  }, [orderFieldsTobeCollected.length]);

  if (showLoading) {
    return <LoadingDialog show={props.showPopup} onClose={props.dismissPopup} />;
  }

  return (
    <>
      <Dialog open={props.showPopup} onClose={props.dismissPopup} maxWidth="sm" fullWidth>
        <DialogTitle id="alert-dialog-title">Place Order</DialogTitle>
        <DialogContent className={styles.popupContentDiv}>
          <Grid container style={{ padding: '8px 16px' }} rowSpacing={2}>
            {orderFieldsTobeCollected.map((orderField) => {
              return (
                <Grid item xs={12} key={orderField.id}>
                  <TextField
                    label={orderField.label}
                    name={orderField.id}
                    type={orderField.type}
                    size="small"
                    color="secondary"
                    fullWidth
                    margin="dense"
                    onChange={formValueChanged}
                    value={customer[orderField.id] || ''}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        {error && (
          <DialogContent className={styles.errorMessageContainer}>
            <span>{error}</span>
          </DialogContent>
        )}
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <div className={styles.CartTotalDiv}>
            <div>Subtotal:</div>
            <div>
              <b>
                {shop?.config?.currency || DEFAULT_CURRENCY} {cart.summary?.total}
              </b>
            </div>
          </div>

          <div>
            <Button style={{ color: red[600] }} onClick={fnOnBack}>
              BACK
            </Button>
            <Button style={{ color: green[600] }} onClick={placeOrderBtnPressed}>
              PLACE ORDER
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
