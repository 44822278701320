import { STORAGE_KEY } from '../../const';
import OrderService from '../../services/OrderService';

const processFormBasedPayment = (formData) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = formData.url;

  for (const [key, value] of Object.entries(formData.secrets)) {
    const encRequestInput = document.createElement('input');
    encRequestInput.type = 'hidden';
    encRequestInput.name = key;
    encRequestInput.value = value;
    form.appendChild(encRequestInput);
  }

  document.body.appendChild(form);
  form.submit();
};

export const handlePayment = async ({ shop, cart, customerDetails, setShowLoading, setError }) => {
  try {
    setError(null);
    setShowLoading(true);

    const customer = customerDetails || JSON.parse(localStorage.getItem(STORAGE_KEY.CUSTOMER_DETAILS) || '{}');
    localStorage.setItem(STORAGE_KEY.CUSTOMER_DETAILS, JSON.stringify(customer));

    const resp = await OrderService.placeOrder(shop, cart, customer);

    if (resp && resp.payment_url) {
      window.location.href = resp.payment_url;
      return;
    } else if (resp && resp.form_data) {
      processFormBasedPayment(resp.form_data);
      return;
    } else {
      setError(resp.message || 'Failed to initiate payment');
      setShowLoading(false);
    }
  } catch (err) {
    const errorMessage = err.response?.data?.message || err.message || 'An error occurred while initiating payment';
    setError(errorMessage);
    setShowLoading(false);
  }
};
